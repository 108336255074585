<template>

<div class="drawer lg:drawer-open">
  <input id="my-drawer-3" type="checkbox" class="drawer-toggle"/>
  <div class="drawer-content flex flex-col">
    <!-- Navbar -->
    <div class="w-full navbar lg:hidden sticky top-0 bg-base-200 shadow-sm shadow-current	z-10">
      <div class="flex-none lg:hidden">
        <label for="my-drawer-3" aria-label="open sidebar" class="btn btn-square btn-ghost">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-6 h-6 stroke-current"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
        
        </label>

        <p class="font-semibold">{{ sidebarLink }}</p>
      </div> 

    </div>
    <!-- Page content here -->
    <div class="container mx-auto flex flex-row justify-center px-4 h-full	">
      <slot></slot>
      
    </div>
  </div> 
  <div class="drawer-side">
    <label for="my-drawer-3" aria-label="close sidebar" class="drawer-overlay"></label> 
    <ul class="menu p-4 w-80 bg-base-200 gap-4 font-semibold min-h-full">
      <!-- Sidebar content here -->
      <div @click="close_navbar" class="lg:invisible">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>

</div>

      <li v-for="(link,key,index) in links" :key="index"><router-link :to="link" class="font-normal" :class="{ 'bg-neutral text-white hover:bg-neutral hover:text-white': props.sidebarLink === key }">{{ key }}</router-link></li>
      <!-- <li class="font-thin">Side Projects</li>

      <li v-for="(link,key,index) in side_projects" :key="index" class="flex flex-row gap-2">
  
  <a :href="link" target="_blank" class="w-full">
    {{ key }}
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 place-self-end		">
  <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
</svg>
    </a>
    </li> -->

      <li class="font-thin">
       
        Recommendations
        
</li>
<li v-for="(link,key,index) in recommendations" :key="index"><router-link :to="link" class="font-normal" :class="{ 'bg-neutral text-white hover:bg-neutral hover:text-white': props.sidebarLink === key }">{{ key }}</router-link>
  
</li>


      <li class="font-thin">
  Online
</li>
<li v-for="(link,key,index) in social" :key="index" class="flex flex-row gap-2 font-normal">
  
  <a :href="link[1]" target="_blank" class="w-full">
    
    {{ key }}
  
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 place-self-end		">
  <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
</svg>
  
  </a>


</li>


<div class="bottom-0">
  <label class="flex cursor-pointer gap-2">
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="5"/><path d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4"/></svg>
  <input type="checkbox" :value="theme" class="toggle theme-controller" @click="savePreference" id="theme-input"/>
  
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg>
</label>
</div>

    </ul>
    
  </div>
</div>

  </template>


<script setup>
import { ref,onMounted,defineProps  } from 'vue'


const props = defineProps({
  sidebarLink: String,
});

let theme=ref('winter')
let links = {'Home':'/','Resume':'/resume'}
let social = {'LinkedIn':['data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkgMGgtMTRjLTIuNzYxIDAtNSAyLjIzOS01IDV2MTRjMCAyLjc2MSAyLjIzOSA1IDUgNWgxNGMyLjc2MiAwIDUtMi4yMzkgNS01di0xNGMwLTIuNzYxLTIuMjM4LTUtNS01em0tMTEgMTloLTN2LTExaDN2MTF6bS0xLjUtMTIuMjY4Yy0uOTY2IDAtMS43NS0uNzktMS43NS0xLjc2NHMuNzg0LTEuNzY0IDEuNzUtMS43NjQgMS43NS43OSAxLjc1IDEuNzY0LS43ODMgMS43NjQtMS43NSAxLjc2NHptMTMuNSAxMi4yNjhoLTN2LTUuNjA0YzAtMy4zNjgtNC0zLjExMy00IDB2NS42MDRoLTN2LTExaDN2MS43NjVjMS4zOTYtMi41ODYgNy0yLjc3NyA3IDIuNDc2djYuNzU5eiIvPjwvc3ZnPg==','https://www.linkedin.com/in/akulchhillar/'],
'Twitter':['data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjQgNC41NTdjLS44ODMuMzkyLTEuODMyLjY1Ni0yLjgyOC43NzUgMS4wMTctLjYwOSAxLjc5OC0xLjU3NCAyLjE2NS0yLjcyNC0uOTUxLjU2NC0yLjAwNS45NzQtMy4xMjcgMS4xOTUtLjg5Ny0uOTU3LTIuMTc4LTEuNTU1LTMuNTk0LTEuNTU1LTMuMTc5IDAtNS41MTUgMi45NjYtNC43OTcgNi4wNDUtNC4wOTEtLjIwNS03LjcxOS0yLjE2NS0xMC4xNDgtNS4xNDQtMS4yOSAyLjIxMy0uNjY5IDUuMTA4IDEuNTIzIDYuNTc0LS44MDYtLjAyNi0xLjU2Ni0uMjQ3LTIuMjI5LS42MTYtLjA1NCAyLjI4MSAxLjU4MSA0LjQxNSAzLjk0OSA0Ljg5LS42OTMuMTg4LTEuNDUyLjIzMi0yLjIyNC4wODQuNjI2IDEuOTU2IDIuNDQ0IDMuMzc5IDQuNiAzLjQxOS0yLjA3IDEuNjIzLTQuNjc4IDIuMzQ4LTcuMjkgMi4wNCAyLjE3OSAxLjM5NyA0Ljc2OCAyLjIxMiA3LjU0OCAyLjIxMiA5LjE0MiAwIDE0LjMwNy03LjcyMSAxMy45OTUtMTQuNjQ2Ljk2Mi0uNjk1IDEuNzk3LTEuNTYyIDIuNDU3LTIuNTQ5eiIvPjwvc3ZnPg==','https://twitter.com/thequestofakul']}
let recommendations = {'Books':'/books','Blogs':'/blogs','Podcasts':'/podcasts','Apps':'/apps','Bookmarks':'/bookmarks','Quotes':'/quotes'}
let side_projects = {'TWOG':'https://twog.akulchhillar.com/','Embeddit': 'https://embeddit.streamlit.app/',
'Marvel Dashboard':''}

onMounted(()=>{
  if(localStorage.getItem("theme")==='dim'){
    document.getElementById('theme-input').click()
  }
 
})
function close_navbar(){
  
  document.getElementById('my-drawer-3').click()
  
}

function savePreference(){
if(document.getElementById('theme-input').checked){
  
  localStorage.setItem("theme", "dim")
  theme.value = 'dim'
}
else{
  localStorage.setItem("theme", "winter")
  theme.value = 'winter'
}
}

</script>






