<template>
      <div v-show="show_metric" class="max-w-fit min-w-fit stats shadow-sm shadow-base-300 lg:shadow-current mt-4">
<div class="stat w-prose">
    <div class="stat-figure text-secondary">
      <div class="avatar online">
        <div class="w-16 rounded-full border-2 border-base-inherint">
          <div v-show="ShowCoverloading" class="skeleton w-16 h-16 rounded-full shrink-0"></div>
          <img @load="OnCoverLoad" :src="image" />
        </div>
      </div>
    </div>
    
    <div class="stat-title">Currently Reading</div>
    <div v-show="ShowDataCoverloading" class="skeleton h-4 stat-value"></div>
    <div v-show="ShowDataCoverloading===false" class="text-2xl font-bold lg:stat-value text-ellipsis overflow-hidden">
      {{ bookdata['book_name'] }}
    </div>
    <div v-show="ShowDataCoverloading===false" class="stat-desc flex flex-col">
     <span class="my-2">By {{bookdata['Author']  }}</span>
      <progress class="progress" :value="progress" max="100"></progress>
    </div>
  </div>
</div>


</template>

<script setup>
 import {ref,onMounted} from 'vue'
 import { supabase } from '@/supabase.js'

let bookdata = ref([])
let image = ref(null)
let progress = ref(0)
let show_metric = ref(false)
let ShowCoverloading = ref(true)
let ShowDataCoverloading = ref(true)

 onMounted(async()=>{

let { data, error } = await supabase
  .from('books')
  .select("*")
  .eq('status', 'Reading')


  if (data.length>0){
    show_metric.value = true
    bookdata.value = data[0]
    progress.value = String((bookdata.value['pages_completed']/bookdata.value['total_pages'])*100)
    image.value = "https://covers.openlibrary.org/b/olid/" + bookdata.value['book_img'] + ".jpg"
    ShowDataCoverloading.value = false
  }


 })


function OnCoverLoad(){

ShowCoverloading.value = false
}</script>