import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ResumeView from '../views/ResumeView.vue'
import BooksView from '../views/BooksView.vue'
import BlogsView from '../views/BlogsView.vue'
import PodcastsView from '../views/PodcastsView.vue'
import AppsView from '../views/AppsView.vue'
import BookmarksView from '../views/BookmarksView.vue'
import QuotesView from '../views/QuotesView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/resume',
    name: 'resume',
   
    component: ResumeView
  },
  {
    path: '/books',
    name: 'books',
    component: BooksView
  },
  {
    path: '/blogs',
    name: 'blogs',
   
    component: BlogsView
  },
  {
    path: '/podcasts',
    name: 'podcasts',
   
    component: PodcastsView
  },
  {
    path: '/apps',
    name: 'apps',
   
    component: AppsView
  },
  {
    path: '/bookmarks',
    name: 'bookmarks',
   
    component: BookmarksView
  },
  {
    path: '/quotes',
    name: 'quotes',
   
    component: QuotesView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
