<template>
    <Navbar sidebarLink="Quotes">
    
  
    <Quotes category="Quotes"/>
      
    </Navbar>
    
    </template>
    
    <script>
    
    import Quotes from '@/components/Quotes.vue'
    import Navbar from '@/components/Navbar.vue'
  
    
    export default {
      name: 'BooksView',
      components: {
        Navbar,Quotes
      }
    }
    </script>
    