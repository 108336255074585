<template>
    <Navbar sidebar-link="Resume">
    
    
      <Resume/>
    </Navbar>
    
    </template>
    
    <script>
    // @ is an alias to /src
    import Resume from '@/components/Resume.vue'
    import Navbar from '@/components/Navbar.vue'
    
    export default {
      name: 'ResumeView',
      components: {
        Navbar,Resume
      }
    }
    </script>
    