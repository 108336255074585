<template>
    <Navbar sidebarLink="Podcasts">
    

    <Recommendations category="Podcasts"/>
      
    </Navbar>
    
    </template>
    
    <script>
    
    import Recommendations from '@/components/Recommendations.vue'
    import Navbar from '@/components/Navbar.vue'

    
    export default {
      name: 'BooksView',
      components: {
        Navbar,Recommendations
      }
    }
    </script>
    