<template>
  <div class="flex flex-col space-y-4">
<ul class="timeline timeline-snap-icon max-md:timeline-compact timeline-vertical mt-12 lg:mt-24">
  <li v-for="(val,name,index) in experience" :key="index">

    <div class="timeline-middle">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" /></svg>
    </div>
    <div class="md:text-end mb-10" :class="index % 2 === 0 ? 'timeline-start': 'timeline-end'">
      <time class="font-mono italic" >{{ val[0] }}</time>
      <div class="text-lg font-normal" >{{ name }} , {{ val[1] }}</div>

    </div>
    <hr/>
    
  </li>

</ul>
<a href="https://drive.google.com/file/d/1Jg6eDoDHRtxFOmJFewsfY_lAAt71YZnw/view?usp=sharing" target="_blank">
  <button class="btn btn-block	my-2 btn-outline">Download Full Resume</button></a>

</div>
    </template>


<script setup>

let experience = {
  'Gartner':['Apr 2024 - Present','Senior Research Specialist'],
    'Gartner ':['Jan 2021 - Mar 2024','Research Specialist'],
'WNS':['Apr 2020 - Jan 2021','Analyst'],
'Evalueserve':['Jun 2018 - Apr 2020','Business Analyst'],}

let work = ['','Work on dashboards powered by Excel, Power BI and Python to help stakeholders make impactful decisons. Build automations using VBA and Power Automate to enable my team members to focus on tasks that are actually worth their time and efforts.',
'Work on financial models for pharmaceutical industry to help clients plan their future business requirements. Primarily worked on Excel and Power BI enabled dashboards.',
'Benchmarked companies on financial and operating statistics, Financial Statement Analysis by gathering information from company reports/filings and from sources such as Bloomberg, Factiva, Thomson Reuters etc.Prepared company profiles, industrial profiles, individual profiles, organizational charts and press search.']

</script>
    
    
    
    
    
    