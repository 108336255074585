<template>
<div class="flex flex-col  mt-12 lg:mt-24 lg:leading-7 gap-4 w-11/12">
  <div class="flex flex-col gap-4 w-52" v-show="showSkeleton">
            <div class="skeleton h-4 w-28"></div>
            <div class="skeleton h-4 w-full"></div>
            <div class="skeleton h-4 w-full"></div>
        </div>
  <p v-for="para in paragraphs">{{ para }}</p>
  
</div>

</template>


<script setup>
import { ref, onMounted } from 'vue'
import {getHome} from '@/api/index'

let paragraphs = ref('')
let showSkeleton = ref(true)

onMounted(() => {

  getHome().then((data)=>{
    showSkeleton.value = false
    paragraphs.value = data
  })

})




</script>





