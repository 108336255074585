export function getHome(){
    
    return fetch('https://akulchhillar.pythonanywhere.com/gethome', {
    }).then((res)=>{return res.json()})
}

export function getBooks(category){
    
    return fetch(`https://akulchhillar.pythonanywhere.com/getrecommendations?category=${category}`, {
    }).then((res)=>{return res.json()})
}

const { Client } = require("@notionhq/client")

// Initializing a client
export const notion = new Client({
  auth: "secret_v4MTe3b7uAcRWBgHDD4ID607ZeNY9bJfuZiZTAENQvQ",
})