<template>
<Navbar sidebarLink="Home">


  <Home/>
</Navbar>

</template>

<script>
// @ is an alias to /src
import Home from '@/components/Home.vue'
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'HomeView',
  components: {
    Navbar,Home
  }
}
</script>
