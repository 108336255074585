<template>
   
        
    <div class="flex flex-col mt-6 lg:mt-12 leading-7 w-11/12 mb-2">
        <div class="flex flex-col gap-4 w-52" v-show="showSkeleton">
            <div class="skeleton h-4 w-28"></div>
            <div class="skeleton h-4 w-full"></div>
            <div class="skeleton h-4 w-full"></div>
        </div>
<ul class="flex flex-col gap-4">
    <li v-for="(book,key,index) in books" :key="index" class="font-normal">
       
<div class="text-xl font-medium">
    <a :href="book['URL']" target="_blank">{{ book['Name'] }}</a>
</div>
<div class="text-sm	">
    {{ book['Author'] }}
</div>
    </li>
</ul>



    </div>

    </template>

    <script setup>


import { ref, onMounted,defineProps } from 'vue'
import {getBooks} from '@/api/index'

let books = ref('')
let showSkeleton = ref(true)

const props = defineProps({
  category: String,
});

onMounted(() => {

    getBooks(props.category).then((data)=>{
showSkeleton.value = false
  books.value = data
})

  

})



</script>



    
    
    
    
    
    