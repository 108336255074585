<template>
    <Navbar sidebarLink="Books">
    
<div class="flex flex-col w-11/12">
<CurrentlyReading/>

<Recommendations category="Books"/>
</div>
    
      
    </Navbar>
    
    </template>
    
    <script>
    
    import Recommendations from '@/components/Recommendations.vue'
    import Navbar from '@/components/Navbar.vue'
    import CurrentlyReading from '@/components/CurrentlyReading.vue';

    
    export default {
      name: 'BooksView',
      components: {
        Navbar,Recommendations,CurrentlyReading
      }
    }
    </script>
    